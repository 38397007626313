import request from '../common/request';

// 呼叫记录
export const getCallRecordList = () => request({
  url: '/api/blade-lock/callrecord/front/list',
  method: 'get',
});

// 呼叫分页
export const getCallRecordPage = (params) => request({
  url: '/api/blade-lock/callrecord/front/page',
  method: 'get',
  params:{
    ...params,
    descs: "create_time"
  }
});

// 签到
export const signIn = () => request({
  url: '/api/blade-lock/guideserverecord/front/sign/in',
  method: 'post',
});

// 签退
export const signOut = () => request({
  url: '/api/blade-lock/guideserverecord/front/sign/out',
  method: 'post',
});

// 获取当前状态
export const getCurrentState = () => request({
  url: '/api/blade-lock/guideserverecord/front/state',
  method: 'get',
});