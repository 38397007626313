<template>
  <div class="notify">
    <van-pull-refresh v-model="refreshing" @refresh="onRefresh" v-if="isSignIn">
      <van-list
        v-model="loading"
        :finished="finished"
        finished-text="没有更多了"
        @load="onLoad"
      >
        <van-cell-group>
          <van-cell icon="clock-o" center :title="item.contentDetail" :label="item.callTime" v-for="item in list" :key="item.id">
            <template #icon>
              <span class="span-icon">{{item.number}}</span>
            </template>
          </van-cell>
        </van-cell-group>
      </van-list>
    </van-pull-refresh>
    <van-empty v-else image="error" description="无权限查看"></van-empty>
    <div class="action">
      <van-button :text="isSignIn?'签退':'签到'" block round type="default" @click="handleBtnClick">
        <template #icon>
          <van-icon name="info-o" @click.stop="handleIconClick" />
        </template>
      </van-button>
    </div>
  </div>
</template>

<script>
import { Cell, CellGroup,Button,Icon,Notify,Empty,List, PullRefresh,  } from "vant";
import { getCallRecordPage, signIn, signOut, getCurrentState  } from "@/api/lock.js";
export default {
  name: "notify",
  components: {
    [List.name]: List,
    [PullRefresh.name]: PullRefresh,
    [CellGroup.name]: CellGroup,
    [Cell.name]: Cell,
    [Button.name]: Button,
    [Icon.name]: Icon,
    [Empty.name]: Empty,
  },
  data(){
    return {
      loading: false,
      finished: false,
      refreshing: false,
      isSignIn: false,
      list:[],
      page: {
        current: 0,
        size: 10,
      },
    }
  },
  mounted(){
    this.getCurrentState()
    this.onLoad();
  },
  methods:{
    onRefresh() {
      this.finished = false;
      this.page.current = 0;
      this.list = [];
      this.onLoad();
    },
    onLoad() {
      this.page.current++;
      this.getCallRecordPage();
    },
    async getCurrentState(){
      const res = await getCurrentState()
      // console.log(res)
      this.isSignIn = res.data
    },
    async getCallRecordPage(){
      const res = await getCallRecordPage({
        current: this.page.current,
        size: this.page.size,
      })
      console.log(res)
      this.refreshing = false;
      this.loading = false;
      if (!res.data.records.length) {
        this.finished = true;
      }
      this.list = this.list.concat(res.data.records);
    },
    async handleBtnClick(){
      let res;
      if(this.isSignIn) {
        res = await signOut()
      } else {
        res = await signIn()
      }
      if(res.success) {
        this.getCurrentState()
        this.onRefresh();
      }
    },
    handleIconClick(){
      const str = this.isSignIn?'签退后可不再接受消息通知':'签到成为导服人员，可以接收群众的一键呼叫、大厅管理消息通知'
      Notify(str);
    }
  }
};
</script>

<style lang="scss" scoped>
.notify {
  width: 100%;
  min-height: 100vh;
  background: #FFFFFF;
}
.span-icon {
  width: 40px;
  height: 40px;
  background: linear-gradient(180deg, #B9C1D6 0%, #ABB6CD 100%);
  border-radius: 20px;
  text-align: center;
  line-height: 40px;
  font-size: 12px;
  color: #FFFFFF;
  margin-right: 10px;
}
.action {
  position: fixed;
  bottom: 20px;
  left: 10%;
  width: 80%;
}
</style>